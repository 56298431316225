import { createSetting } from '@shopex/finder'
import { useDialog } from '@shopex/finder'
import { stop } from '../api'
import { MessageBox } from 'element-ui'

export const StatusOption = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '进行中',
    value: 'going'
  },
  {
    label: '未开始',
    value: 'not_start'
  },
  {
    label: '已结束',
    value: 'stopped'
  }
]

export const ITEM_STATUS_OPTIONS = [
  { label: '优惠券', value: 1 },
  { label: '额外保修', value: 2 },
  { label: '实物赠品', value: 3 },
  { label: '虚拟赠品', value: 4 },
]


export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '活动名称',
      key: 'activity_title',
    },
    {
      name: '活动ID',
      key: 'activity_id',
    },
    {
      name: '权益类型',
      key: 'interests_type',
      type: 'select',
      options: ITEM_STATUS_OPTIONS,
      clearable: true
    },
  ],
  columns: [
    {
      name: '活动ID',
      key: 'activity_id',
      width: 90
    },
    {
      name: '活动名称',
      key: 'activity_title',
      minWidth: 150
    },
    {
      name: '活动状态',
      key: 'status',
      minWidth: 100,
      render: (h, { row }) => {
        const starttime = Number(row.activity_begin_time)
        const endtime = Number(row.activity_end_time)
        const now_time = new Date().getTime() / 1000
        if(starttime > now_time) {
          return <el-tag type="warning" effect="light">未开始</el-tag>
        } else if (starttime <= now_time && endtime >= now_time) {
          return <el-tag type="success" effect="light">进行中</el-tag>
        } else {
          return <el-tag type="danger" effect="light">已结束</el-tag>
        }
      }
    },
    {
      name: '权益类型',
      key: 'interests_type',
      minWidth: 100,
      formatter: (v) => ITEM_STATUS_OPTIONS.find(item => item.value == v)?.label
    },
    {
      name: '有效时间',
      key: 'finish_time',
      minWidth: 180,
      render: (h, { row }) => {
        const start = vm.$options.filters['datetime'].call(vm, new Date(parseInt(row.activity_begin_time)), 'yyyy-MM-dd hh:mm:ss' )
        const end = vm.$options.filters['datetime'].call(vm, new Date(parseInt(row.activity_end_time)), 'yyyy-MM-dd hh:mm:ss' )
        return `${start}~${end}`
      }
    },
    {
      name: '适用门店',
      key: 'finish_time',
      minWidth: 100,
      render(_, { row }) {
        const id = row.activity_id
        return <span style="color: #409EFF; cursor: pointer;" onClick={() => {
          useDialog(null, {
            title: `查看适用门店`,
            width: '60%',
            id,
            component: () => import('../component/shop/index.vue'),
            actions: []
          })
        }}>查看门店</span>
      }
    }
  ],
  actions: [
    {
      name: '新增',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.$router.push({ path: vm.matchHidePage('operation') })
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const id = row[0].activity_id
          vm.$router.push({ path: vm.matchHidePage('operation'), query: { id }  })
        }
      }
    },
    {
      name: '导入适用门店',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          const id = rows[0].activity_id
          vm.openImportAddDialog(id)
        }
      }
    },
    {
      name: '中止',
      type: 'button',
      buttonType: 'text',
      visible: (row) => {
        const endtime = Number(row.activity_end_time)
        const now_time = new Date().getTime() / 1000
        if(endtime < now_time) return false
        return true
      },
      action: {
        handler: (rows) => {
          MessageBox.confirm('请确认是否中止？', '提示').then(async () => {
            const id = rows[0].activity_id
            await stop(id)
            vm.$message.success('中止成功')
            vm.refresh()
          })
        }
      }
    },
  ]
})
