import { createSetting } from '@shopex/finder'
import { useDialog } from '@shopex/finder'
import { stop } from '../api'
import { MessageBox } from 'element-ui'

export const StatusOption = [
  {
    label: '全部',
    value: 'all'
  },
  {
    label: '待生效',
    value: 'wait'
  },
  {
    label: '生效中',
    value: 'running'
  },
  {
    label: '已失效',
    value: 'over'
  }
]

export const GOODS_TYPE = [
  { label: '实物赠品', value: 'gift_normal' },
  { label: '虚拟赠品', value: 'gift_services' },
]

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '活动标题',
      key: 'activity_name',
    },
    {
      name: '活动ID',
      key: 'activity_id',
    },
    {
      name: '赠品类型',
      key: 'gift_type',
      type: 'select',
      options: GOODS_TYPE
    },
  ],
  columns: [
    {
      name: '活动ID',
      key: 'activity_id',
      width: 90
    },
    {
      name: '活动标题',
      key: 'activity_name',
      minWidth: 150
    },
    {
      name: '状态',
      key: 'activity_status_desc',
      minWidth: 100,
      formatter: (v) => StatusOption.find(item => item.value === v).label
    },
    {
      name: '赠品类型',
      key: 'gift_type',
      minWidth: 100,
      render: (h, { row }) => {
        return row.gift_type == 'gift_normal' ? '实体赠品' : '虚拟赠品'
      }
    },
    {
      name: '生效时间',
      key: 'valid_time_desc',
      minWidth: 180
    },
  ],
  actions: [
    {
      name: '添加活动',
      slot: 'header',
      type: 'button',
      buttonType: 'primary',
      action: {
        handler: () => {
          vm.$router.push({ path: vm.matchHidePage('operation') })
        }
      }
    },
    {
      name: '编辑',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          const id = row[0].activity_id
          vm.$router.push({ path: vm.matchHidePage('operation'), query: { id }  })
        }
      }
    },
    {
      name: '中止',
      type: 'button',
      buttonType: 'text',
      visible: (row) => {
        return row.activity_status_desc !=='over'
      },
      action: {
        handler: (rows) => {
          MessageBox.confirm('活动中止不会影响已经产生订单的赠品发放，是否继续中止', '中止活动').then(async () => {
            const id = rows[0].activity_id
            await stop(id)
            vm.$message.success('中止成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '上传门店',
      type: 'button',
      buttonType: 'text',
      visible: (row) => {
        return row.activity_status_desc !=='over' && row.distributor_type != 'all'
      },
      action: {
        handler: (rows) => {
          vm.$confirm('本次操作会覆盖历史数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type:'warning'
          })
            .then(() => {
              const id = rows[0].activity_id
              vm.openImportAddDialog(id)
            })
            .catch(() => {})
        }
      }
    },
    {
      name: '查看参与门店',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (rows) => {
          const id = rows[0].activity_id
          const status = rows[0].activity_status_desc
          useDialog(null, {
            title: `查看参与门店`,
            width: '60%',
            id,
            status,
            component: () => import('../comps/shop/index.vue'),
            actions: []
          })
        }
      }
    }
  ]
})
