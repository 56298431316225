<template>
  <div class="buyxgivey-operation" v-loading="loading">
    <el-form ref="basic" :model="formModel" :rules="formRules" label-width="150px" label-position="left">
      <co-fold-block title="活动信息">
        <el-form-item label="活动标题" prop="activity_name">
          <el-input v-model="formModel.activity_name" placeholder="请输入活动标题" />
        </el-form-item>
        <el-form-item label="赠品订单活动备注" prop="order_remark">
          <el-input v-model="formModel.order_remark" placeholder="请输入活动备注"/>
        </el-form-item>
        <el-form-item label="生效时间" prop="activity_date">
          <el-date-picker
            v-model="formModel.activity_date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
      </co-fold-block>
      <co-fold-block title="购买商品">
        <el-form-item label="商品履约仓" prop="need_type">
          <el-radio-group v-model="formModel.need_type" :disabled="isEdit">
            <el-radio label="item_all">全部</el-radio>
            <el-radio label="item_distributor">门店库存</el-radio>
            <el-radio label="item_warehouse">云仓库存</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="需购买商品" prop="need_item_ids">
          <SkuSelector
            :data="skuList"
            @change="handleSelectSku"
          />
        </el-form-item>
      </co-fold-block>
      <co-fold-block title="获得赠品">
        <el-form-item label="赠品类型" prop="gift_stock_type">
          <el-radio-group v-model="formModel.gift_type" @input="giftTypeChange">
            <el-radio-button label="gift_normal">实物赠品</el-radio-button>
            <el-radio-button label="gift_services">虚拟赠品</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="库存策略" prop="gift_stock_type">
          <el-radio-group v-model="formModel.gift_stock_type">
            <el-radio label="stock_item_distributor">赠完即止</el-radio>
            <el-radio label="none">不限库存</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="领取方式" prop="receiver_gift_type">
          <el-radio-group v-model="formModel.receiver_gift_type" :disabled="isEdit">
            <el-radio label="self_pickup" v-if="formModel.gift_type === 'gift_normal'">到店领取</el-radio>
            <el-radio label="directly" v-else>直接发放</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="可领取开始时间">
          <span class="tip-color">活动开始时间</span>
        </el-form-item>
        <el-form-item label="可领取截止时间">
          <span class="tip-color">活动结束时间</span>
        </el-form-item>
        <div v-if="formModel.gift_type === 'gift_normal'">
          <el-form-item label="可核销开始时间" prop="receiver_gift_start_time">
            <div style="display: flex;">
              <span class="mr-12">用户货品订单签收</span>
              <el-input-number
                v-model="formModel.receiver_gift_start_time"
                :controls="false"
                :min="0"
                :precision="0"
                placeholder="请输入"
              />
              <span class="ml-12">*24小时后可领取</span>
            </div>
          </el-form-item>
          <el-form-item label="可核销截止时间" prop="receiver_gift_end_time_type">
            <template v-slot:label>
              <span>
                可核销截止时间
                <el-popover trigger="hover" placement="top-start">
                  统一过期时间早于等于活动结束时间，可能导致部分用户无法领取
                  <i slot="reference" class="el-icon-info" />
                </el-popover>
              </span>
            </template>
            <el-radio-group v-model="formModel.receiver_gift_end_time_type" @input="handleGiftTypeChange">
              <el-radio label="fixed">统一日期截止</el-radio>
              <el-radio label="day">用户可核销开始时间+n天截止</el-radio>
            </el-radio-group>
            <div>
              <el-date-picker
                v-if="formModel.receiver_gift_end_time_type === 'fixed'"
                v-model="formModel.receiver_gift_end_time"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              />
              <el-input-number
                v-else
                v-model="formModel.receiver_gift_end_time"
                :controls="false"
                :min="0"
                :precision="0"
                placeholder="请输入"
                style="display: inline-block;"
              />
              <span class="ml-12">的 23:59:59 截止</span>
            </div>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="卡密发放时间" prop="receiver_gift_start_time">
            <div style="display: flex;">
              <span class="mr-12">用户平台订单签收</span>
              <el-input-number
                v-model="formModel.receiver_gift_start_time"
                :controls="false"
                :min="0"
                :precision="0"
                placeholder="请输入"
              />
              <span class="ml-12">天后发放</span>
            </div>
          </el-form-item>
        </div>
        <el-form-item :label="formModel.gift_type === 'gift_normal' ? '实物赠品' : '虚拟赠品'" prop="gift_rel_data">
          <GiftGoods v-model="formModel.gift_rel_data" :giftType="formModel.gift_type" />
        </el-form-item>
      </co-fold-block>
      <co-fold-block title="参与门店">
        <el-form-item label="选择参与门店" prop="distributor_type">
          <el-radio-group v-model="formModel.distributor_type">
            <el-radio label="all">全部门店</el-radio>
            <el-radio label="distributor">部分门店</el-radio>
          </el-radio-group>
          <div v-show="formModel.distributor_type === 'distributor'">指定参与门店请保存活动设置后，前往列表页导入</div>
        </el-form-item>
      </co-fold-block>
    </el-form>
    <FooterSubmitButtons @cancel="hanldeCancel" @submit="handleSubmit"/>
  </div>
</template>

<script>
import { create, update, detail } from './api'
import FooterSubmitButtons from './comps/footer-submit-buttons'
import GiftGoods from './comps/gift-goods'
import SkuSelector from '@/components/function/skuSelector'

export default {
  components: { FooterSubmitButtons, GiftGoods, SkuSelector },
  data () {
    return {
      loading: false,
      id: null,
      skuList: [],
      formModel: {
        activity_name: '',
        order_remark: '',
        activity_date: [],
        need_item_ids: [],
        need_type: 'item_all',
        gift_stock_type: 'stock_item_distributor',
        gift_type: 'gift_normal',
        receiver_gift_type: 'self_pickup',
        receiver_gift_start_time: undefined,
        receiver_gift_start_time_type: '',
        receiver_gift_end_time_type: 'fixed',
        receiver_gift_end_time: undefined,
        gift_rel_data: [],
        distributor_type: 'all'
      },
      formRules: {
        activity_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        activity_date: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        need_type: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        need_item_ids: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        gift_stock_type: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        receiver_gift_type: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        receiver_gift_start_time: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        receiver_gift_end_time: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        receiver_gift_end_time_type: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        gift_rel_data: [
          { required: true, message: '请选择', trigger: 'blur' },
        ],
        distributor_type: [
          { required: true, message: '请选择', trigger: 'blur' },
        ]
      },
    }
  },
  computed: {
    isEdit() {
      return !!this.id
    }
  },
  async mounted() {
    const id = this.$route.query.id
    if(id) {
      this.id = id
      this.initDetail()
    }
  },
  methods: {
    async initDetail() {
      try {
        this.loading = true
        const res = await detail(this.id)
        let { data } = res.data
        this.formModel = Object.assign(this.formModel, data)
        this.formModel.activity_date = [data.start_time, data.end_time]
        this.skuList = [...data.need_item_data]
        delete this.formModel?.need_item_data // 删除无需的字段
        const ids = this.skuList?.map(item => item.item_id)
        this.formModel.need_item_ids = ids
        this.formModel.gift_rel_data = data.gift_rel_data.map(item => ({
          item_id: item.item_id,
          item_name: item.item_name,
          item_bn: item.item_bn,
          item_spec_desc: item.item_spec
        }))
      } catch (e) {
        console.log("🚀🚀🚀🚀🚀🚀 ~ initDetail ~ e:", e)
      } finally {
        this.loading = false
      }
    },
    handleGiftTypeChange() {
      // 每次切换清空
      this.formModel.receiver_gift_end_time = undefined
    },
    hanldeCancel() {
      this.$router.go(-1)
    },
    handleSelectSku(data) {
      const ids = data?.map(item => item.item_id)
      this.formModel.need_item_ids = ids
    },
    async handleSubmit() {
      try {
        await this.$refs.basic.validate()
        let { activity_date, receiver_gift_end_time_type, receiver_gift_end_time } = this.formModel

        if(receiver_gift_end_time_type === 'fixed') {
          if(new Date(receiver_gift_end_time).getTime() <= (new Date().getTime() - 8.64e7)) {
            this.$message.error('可核销截止时间不能小于当前时间')
            return
          }
          // 如果receiver_gift_end_time 不包含 23:59:59 则需要加上去
          if(receiver_gift_end_time.indexOf('23:59:59') === -1) {
            receiver_gift_end_time = receiver_gift_end_time + ' 23:59:59'
          }
        }
        let data = {
          ...this.formModel,
          receiver_gift_end_time,
          start_time: activity_date[0],
          end_time: activity_date[1],
        }
        this.loading = true
        if(this.id) {
          data.id = this.id
          await update(data)
        } else {
          await create(data)
        }
        this.$message.success('保存成功')
        this.hanldeCancel()
      } finally{
        this.loading = false
      }
    },
    giftTypeChange(val) {
      if (val === 'gift_normal') {
        this.formModel.receiver_gift_type = 'self_pickup'
        this.formModel.receiver_gift_start_time = undefined
        this.formModel.receiver_gift_start_time_type = 'day'
        this.formModel.receiver_gift_end_time_type = 'fixed'
      } else {
        this.formModel.receiver_gift_type = 'directly'
        this.formModel.receiver_gift_start_time = undefined
        this.formModel.receiver_gift_start_time_type = 'day'
        this.formModel.receiver_gift_end_time_type = ''
        this.formModel.receiver_gift_end_time = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.buyxgivey-operation {
  position: relative;
  ::v-deep .el-input {
    width: 250px;
  }
  ::v-deep .el-input-number {
    width: 100px;
    .el-input {
      width: 100px;
    }
  }
  .ml-12 {
    margin-left: 12px;
  }
  .mr-12 {
    margin-right: 12px;
  }
  .channel-select {
    display: flex;
  }
  .form-item-desc {
    margin-left: 120px;
    font-size: 12px;
    color: #909399;
  }
  .tip-color {
    color: #909399;
  }
}
</style>
