<template>
  <div>
    <el-button style="margin-bottom: 12px;" type="primary" @click="handleAddGoods">选择赠品</el-button>
    <SpFinder
      ref="finder"
      :data="tableData"
      :setting="setting"
      no-selection
      :show-pager="false"
      style="max-width: 1200px;"
      class="clear-finder-style"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    giftType: {
      type: String,
      default: 'gift_normal'
    }
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  data () {
    return {
      tableData: []
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (val) {
        this.tableData = val
      }
    },
    tableData: {
      deep: true,
      immediate: true,
      handler (val) {
        this.$emit('update', val)
      }
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {},
  methods: {
    async handleAddGoods() {
      const { data } = await this.$picker.gift({
        data: this.tableData,
        rowKey: 'item_id',
        queryParams: {
          goods_type: this.giftType === 'gift_normal' ? 'normal' : 'services',
        },
      })
      this.tableData = data.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name,
        item_bn: item.item_bn,
        item_spec_desc: item.item_spec_desc
      }))
    },
    handleDelete(row) {
      this.tableData = this.tableData.filter(item => item.item_id !== row[0].item_id)
    }
  }
}
</script>

<style scoped>

</style>
