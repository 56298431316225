<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1">
      <el-row :gutter="20">
        <el-col :md="4" :lg="8">
          <el-input
            clearable
            class="input-b"
            placeholder="规则名称"
            v-model="params.rule_title"
            @change="getDistributorShowRuleList">
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :md="4" :lg="8">
          <el-button
            v-if="login_type === 'disti'"
            size="mini"
            type="primary"
            icon="plus"
            @click="addActivityData">
            添加
          </el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="params.platform" @tab-click="handleClick" >
        <el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
        <el-tab-pane label="抖音小程序" v-if="login_type === 'disti' && admin_type === 'order'" name="byte_mini"></el-tab-pane>
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="rule_id" width="60" label="编号"></el-table-column>
          <el-table-column prop="rule_title" label="规则名称"></el-table-column>
<!--          <el-table-column prop="rule_desc" label="规则描述"></el-table-column>-->
          <el-table-column prop="total_fee" width="200" label="创建时间">
            <template slot-scope="scope">
              <div>
                <template>
                  {{ scope.row.created | datetime("yyyy-MM-dd hh:mm:ss") }}
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div>
                <el-button
                  v-if="login_type === 'disti'"
                  type="text"
                  @click="editDistributorShowRule(scope.row)"
                >
                  编辑
                </el-button>
                <ImportDistributor
                  v-if=""
                  buttonName="上传展示门店"
                  buttonType="text"
                  fileName="上传展示门店模版"
                  fileType="distributor_show_rule_pool"
                  :relId="scope.row.rule_id"
                ></ImportDistributor>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count'
            :page-size="params.page_size">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-dialog
      title="添加门店展示规则"
      :visible.sync="showRuleDialogVisible"
      :before-close="beforeCloseShowRuleDialog"
      width="60%">

      <el-form ref="form" :model="form" label-width="140px" label-position="left">
        <el-form-item label="活动名称">
          <el-input v-model="form.rule_title" class="input-b"></el-input>
        </el-form-item>
        <el-form-item label="店铺头图">
          <div>
            <div>
              <div class="upload-box">
                <div @click="handleHomeImgChange">
                  <img v-if="form.distributor_header_image" :src="wximageurl + form.distributor_header_image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
                <div class="goodspic-mask" v-if="form.distributor_header_image">
                  <div class="iconfont icon-trash-alt" @click="removePicsImg()"></div>
                </div>
              </div>
            </div>
            <imgPicker :dialog-visible="homeimgDialog" :sc-status="true" @chooseImg="pickImg"
              @closeImgDialog="handleHomeImgChange"></imgPicker>
          </div>
        </el-form-item>
<!--        <el-form-item label="活动描述">
          <el-input
            class="input-b"
            type="textarea"
            :rows="6"
            placeholder="请输入内容"
            v-model="form.rule_desc">
          </el-input>
        </el-form-item>-->
        <el-form-item label="选择展示门店">
          <el-button type="primary" plain size="mini" @click="handleSetDistributor">设置门店</el-button>
        </el-form-item>
      </el-form>

      <el-row>
        <el-col>
          <el-input style="width: 400px" placeholder="门店Apple ID；多个门店请用`,`分割" v-model="choseDistributorShopCodes" clearable>
            <el-button slot="append" icon="el-icon-search" @click="distributorShopCodeSearch"></el-button>
          </el-input>
          <span>
              <el-button type="primary" @click="batchDeleteDistributor">删除</el-button>
          </span>
        </el-col>
      </el-row>
      <el-table
        v-loading="distributorLoading"
        :data="shopCodeSelectDistributors"
        @selection-change="handleSelectionChange"
        row-key="distributor_id"
        ref="ruleDistributorTable"
        style="width: 100%">
        <el-table-column type="selection" align="center" label="全选" :reserve-selection="true"></el-table-column>
        <el-table-column
          prop="name"
          label="门店名称">
        </el-table-column>
        <el-table-column
          prop="shop_code"
          label="门店Apple ID"
          width="180">
        </el-table-column>
        <el-table-column
          prop="dealer_name"
          label="所属经销商">
        </el-table-column>
        <el-table-column
          width="100"
          prop=""
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteDistributor(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="beforeCloseShowRuleDialog">取 消</el-button>
        <el-button type="primary" @click="createDistributorShowRule">确 定</el-button>
      </span>
    </el-dialog>

    <DistributorSelect
      :store-visible="distributorVisible"
      :is-valid="true"
      :relDataIds="distributor_info"
      :get-status="setDistributorStatus"
      :platform="params.platform"
      @chooseStore="chooseDistributorAction"
      @closeStoreDialog="closeDistributorDialogAction"
      returnType="selectRow"
    ></DistributorSelect>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DistributorSelect from '@/components/function/distributorSelect'
import ImportDistributor from '@/components/importDistributor'
import imgPicker from '@/components/imageselect'
import {
  getDistributorShowRuleList,
  createDistributorShowRule,
  updateDistributorShowRule,
  getDistributorShowRuleDetail,
} from '@/api/shop'

export default {
  provide() {
    return {
    }
  },
  components: {
    DistributorSelect,
    ImportDistributor,
    imgPicker
  },
  data () {
    return {
      distributorLoading: false,
      showRuleDialogVisible: false,
      distributorVisible: false,
      loading: false,
      params: {
        page: 1,
        page_size: 20,
        rule_title: '',
        platform: 'wxapp'
      },
      total_count: 0,
      list: [],
      form: {
        rule_id: 0,
        rule_title: '',
        rule_desc: '',
        distributor_ids: [],
        platform: '',
        distributor_header_image: ''
      },
      distributor_info: [],
      selectedDistributors: [],
      setDistributorStatus: false,
      choseDistributorShopCodes: '',
      choseNum: '',
      choseDistributorIds: [],
      homeimgDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type',
      'admin_type'
    ]),
    shopCodeSelectDistributors () {
      const result = this.selectedDistributors.filter(item => !item.hidden)
      return this.choseNum ? result : this.selectedDistributors
    }
  },
  methods: {
    handleClick (tab, event) {
      this.params.page = 1
      this.getDistributorShowRuleList()
    },
    beforeCloseShowRuleDialog () {
      this.showRuleDialogVisible = false
      this.form = this.$options.data().form;
      this.distributor_info = this.selectedDistributors = []
    },
    createDistributorShowRule () {
      this.form.distributor_ids = []
      if (this.selectedDistributors.length > 0) {
        for (let i = 0; i < this.selectedDistributors.length; i++) {
          const id = this.selectedDistributors[i].distributor_id
          this.form.distributor_ids.push(Number(id))
        }
      } else {
        this.form.distributor_ids = []
      }

      let params = JSON.parse(JSON.stringify(this.form))
      params.platform = this.params.platform
      if (params.rule_id) {
        updateDistributorShowRule (params).then(response => {
          if  (response.data.data.success) {
            this.$message.success('更新成功')
            this.getDistributorShowRuleList()
            this.showRuleDialogVisible = false
          }
        })
      } else {
        createDistributorShowRule (params).then(response => {
          if  (response.data.data.success) {
            this.$message.success('添加成功')
            this.getDistributorShowRuleList()
            this.showRuleDialogVisible = false
          }
        })
      }
    },
    editDistributorShowRule (row) {
      this.distributorLoading = true
      this.showRuleDialogVisible = true
      this.selectedDistributors = []
      this.form.distributor_ids = []
      let params = {
        rule_id: row.rule_id
      };
      getDistributorShowRuleDetail(params).then(response => {
        this.form.rule_id = response.data.data.rule_id
        this.form.rule_title = response.data.data.rule_title
        this.form.rule_desc = response.data.data.rule_desc
        this.form.distributor_header_image = response.data.data.distributor_header_image
        this.selectedDistributors = response.data.data.distributor_info
        this.distributorLoading = false

        for (let i = 0; i < this.selectedDistributors.length; i++) {
          const id = this.selectedDistributors[i].distributor_id
          this.form.distributor_ids.push(Number(id))
        }
      })
    },
    handleSetDistributor () {
      this.distributor_info = JSON.parse(JSON.stringify(this.selectedDistributors))
      this.distributorVisible = true
    },
    chooseDistributorAction (data) {
      this.distributorVisible = false
      if (!data || data === null || data.length <= 0) {
        this.selectedDistributors = []
        this.form.distributor_ids = []
        return
      }
      this.selectedDistributors = JSON.parse(JSON.stringify(data))
      this.form.distributor_ids = []
      for (let i = 0; i < data.length; i++) {
        const id = data[i].distributor_id
        this.form.distributor_ids.push(Number(id))
      }
    },
    deleteDistributor (row) {
      let distributorId = row.distributor_id
      for (let i = 0; i < this.selectedDistributors.length; i++) {
        if (this.selectedDistributors[i].distributor_id == distributorId) {
          this.selectedDistributors.splice(i, 1)
        }
      }
      for (const index in this.form.distributor_ids) {
        if (this.form.distributor_ids[index] == distributorId) {
          this.form.distributor_ids.splice(index, 1)
        }
      }
    },
    closeDistributorDialogAction () {
      console.log(this.distributor_info, this.selectedDistributors)
      this.distributorVisible = false
    },
    addActivityData () {
      this.showRuleDialogVisible = true
      this.selectedDistributors = []
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getDistributorShowRuleList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.page_size = pageSize
      this.getDistributorShowRuleList()
    },
    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getDistributorShowRuleList() {
      this.loading = true
      let filter = {
        ...this.params,
      }
      getDistributorShowRuleList(filter).then(response => {
        this.list = response.data.data.list
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    distributorShopCodeSearch () {
      let shopCodes = []
      if (this.choseDistributorShopCodes) {
        shopCodes = this.choseDistributorShopCodes.split(',')
        for (const index in shopCodes) {
          shopCodes[index] = shopCodes[index].trim()
        }
      }
      for (const index in this.selectedDistributors) {
        if (shopCodes.length > 0) {
          this.selectedDistributors[index].hidden = shopCodes.indexOf(this.selectedDistributors[index]['shop_code']) < 0
        } else {
          this.selectedDistributors[index].hidden = false
        }
      }
      this.choseNum = Math.random()
    },
    handleSelectionChange (val) {
      let distributorIds = []
      for (let i in val) {
        distributorIds.push(val[i].distributor_id)
      }
      this.choseDistributorIds = distributorIds
    },
    batchDeleteDistributor () {
      if (this.choseDistributorIds.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择要删除的店铺'
        })
        return false
      }
      for (const index in this.selectedDistributors) {
        for (const key in this.choseDistributorIds) {
          if (this.choseDistributorIds[key] === this.selectedDistributors[index].distributor_id) {
            this.selectedDistributors.splice(index, 1)
            this.form.distributor_ids.splice(this.form.distributor_ids.indexOf(this.selectedDistributors[index].distributor_id), 1)
          }
        }
      }
      this.choseDistributorIds = []
      this.$refs['ruleDistributorTable'].clearSelection()
    },
    handleHomeImgChange() {
      this.homeimgDialog = !this.homeimgDialog
    },
    pickImg(data) {
      this.form.distributor_header_image = data.url
      this.handleHomeImgChange()
    },
    removePicsImg() {
      this.form.distributor_header_image = ''
    }
  },
  mounted () {
    this.getDistributorShowRuleList()
  },
  watch: {
    '$route' () {
      this.getDistributorShowRuleList()
    }
  },
}
</script>
<style scoped lang="scss">
.upload-box{
  position: relative;
}
.goodspic-mask {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 25%;
  height: 25%;
  background: rgba(0, 0, 0, .2);
  cursor: pointer;

  &.on {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconfont {
    margin: 0 8px;
    font-size: 20px;
    color: #fff;
  }
}
</style>
