<template>
  <el-form ref="form" :model="form" label-width="110px" class="distributor_editor">
    <section class="section section-white content-padded" style="padding-bottom: 70px" onload="init()">
      <el-row>
        <el-col :span="19" style="padding-right:20px">
          <el-card>
            <div slot="header" class="clearfix">
              <span>基础信息</span>
            </div>
            <el-row>
              <el-col :span="12">
                <el-form-item label="店铺名称" required>
                  <el-input v-model="form.name" class="input-b" placeholder="请先完成下方地理位置的选择"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="门店Apple ID" prop="shop_code" :rules="rules.shop_code">
                  <el-input v-model="form.shop_code" class="input-b" :maxlength="10" placeholder="门店Apple ID"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="店铺别名">
                  <el-input v-model="form.alias_name" class="input-b" placeholder="输入店铺别名"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="抖店ID">
                  <el-input v-model="form.byte_mini_shop_id" class="input-b" placeholder="输入抖店ID"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="联系方式">
                  <el-input v-model="form.mobile" class="input-b" placeholder="请输入联系方式"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="!distributor_type">
              <el-col :span="12">
                <el-form-item label="所属经销商" required>
                  <span v-if="relDealers[0] != undefined">{{ relDealers[0].name || '--' }}</span>
                  <el-popover placement="right" width="300" style="margin:0px 10px;color:#409EFF" trigger="hover"
                    v-if="relDealers[0] != undefined">
                    <div>
                      <div style="display:flex;justify-content: space-between;">
                        <span>经销商名称：</span>
                        <span>{{ relDealers[0].name || '--' }}</span>
                      </div>
                      <div style="margin-top:10px;display:flex;justify-content: space-between;">
                        <span>经销商别名：</span>
                        <span>{{ relDealers[0].alias_name || '--' }}</span>
                      </div>
                      <div style="margin-top:10px;display:flex;justify-content: space-between;">
                        <span>经销商HQID：</span>
                        <span>{{ relDealers[0].dealer_hqid || '--' }}</span>
                      </div>
                      <div style="margin-top:10px;display:flex;justify-content: space-between;">
                        <span>分销商HQID：</span>
                        <span>{{ relDealers[0].distributor_hqid || '--' }}</span>
                      </div>
                      <div style="margin-top:10px;display:flex;justify-content: space-between;">
                        <span>分销商VAD：</span>
                        <span>{{ relDealers[0].distributor_vad || '--' }}</span>
                      </div>
                      <div style="margin-top:10px;display:flex;justify-content: space-between;">
                        <span>渠道RTM：</span>
                        <span>{{ relDealers[0].channel_rtm || '--' }}</span>
                      </div>
                    </div>
                    <el-button type="text" slot="reference">查看</el-button>
                  </el-popover>
                  <el-button v-if="!$route.query.distributor_id" size="small" type='primary' plain @click="openDialog">选择所属经销商</el-button>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="!distributor_type">
              <el-col :span="12">
                <el-form-item label="是否启用">
                  <el-radio-group v-model="form.is_valid">
                    <el-radio label="true" v-if="form.examine_status == 0 || form.examine_status == 4">启用</el-radio>
                    <el-radio label="false">禁用</el-radio>
                    <el-radio label="delete">废弃(相当于删除)</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row >
              <el-col :span="12">
                <el-form-item label="店铺类型">
                  <el-radio-group v-model="form.shop_type">
                    <el-radio label="monopolized">授权专营店</el-radio>
                    <el-radio label="arrondi">授权专区</el-radio>
、                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="店铺定位" required>
                  <div class="frm-tips">店铺定位后保存店铺经纬度，才可以开启自提，否则店铺不支持自提功能</div>
                  <div style='display:flex;'>
                    <el-cascader v-model="form.regions_id" :options="regions" @change="handleRegionChange"></el-cascader>
                    <el-input v-model="form.address" @keydown.enter.native="searchKeyword" id="keyword"
                      placeholder="请输入地址"></el-input>
                    <el-button type="primary" @click="searchKeyword">搜索定位</el-button>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <div id="qqmap_container"></div>
                </el-form-item>
                <el-form-item inline="true" class="demo-form-inline" label="店铺经纬度">
                  <el-col :span="3">
                    <el-input v-model="form.lng" placeholder="经度" @input="setMapCenter"></el-input>
                  </el-col>
                  <el-col :span="1" class="content-center">-</el-col>
                  <el-col :span="3">
                    <el-input v-model="form.lat" placeholder="纬度" @input="setMapCenter"></el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form-item label="取货尊享">
                  <div>
                    <el-input v-model="form.shop_enjoy" class="input-b" placeholder="输入尊享内容"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="所属城市级别">
                  <div>
                    <el-input v-model="form.city_lv" class="input-b" placeholder="请输入所属城市级别"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="店铺等级">
                  <div>
                    <el-input v-model="form.distributor_lv" class="input-b" placeholder="请输入店铺等级"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="商城信息">
                  <div>
                    <el-input v-model="form.market_name" class="input-b" placeholder="请输入商城信息"></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="发件人手机号">
                  <div>
                    <el-input v-model="form.contract_phone" placeholder="顺丰发件人手机号"></el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-form-item label="营业时间">
                <div v-for="(item, index) in hours" class="time-hours" :key="index">
                  <el-select v-model="item.startWeek" placeholder="起始星期">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select v-model="item.endWeek" placeholder="结束星期">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <el-time-select placeholder="起始时间" v-model="item.startTime"
                    :picker-options="{ start: '00:00', step: '00:30', end: '23:59' }"> </el-time-select>
                  <el-time-select placeholder="结束时间" v-model="item.endTime"
                    :picker-options="{ start: '00:00', step: '00:30', end: '23:59', minTime: item.startTime }"></el-time-select>
                  <div v-if="index >= 1" class="setting-remove" @click="removeItem(index)"><i
                      class="iconfont icon-trash-alt"></i></div>
                </div>
                <el-button :disabled="hours.length >= 3" type="primary" size="medium" plain
                  @click="addItem">添加营业时间</el-button>
                <p class="frm-tips">24小时制，如10：00-20：30</p>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="店铺LOGO">
                <div @click="handleImgPicker('logo')" class="upload-box">
                  <img v-if="form.logo" :src="form.logo" class="avatar" />
                  <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                </div>
                <div class="frm-tips">只能上传jpg/png文件，且不超过2M </div>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="店铺Banner">
                <div class="pics-box">
                  <ul class="goodspic-wrap">
                    <draggable v-model="form.shop_pics" :options="dragIssuesOptions" class="components-view">
                      <li v-for="(item, index) in form.shop_pics" :key='index' class="goodspic">
                        <img :src="wximageurl + item" />
                        <div class="goodspic-mask">
                          <div class="iconfont icon-trash-alt" @click="removePicsImg(index)"></div>
                          <div class="iconfont icon-arrows-alt"></div>
                        </div>
                      </li>
                    </draggable>
                  </ul>
                  <div v-if="form.shop_pics.length < 5" class="upload-box" @click="handleImgPicker('shop_pics')">
                    <i class="iconfont icon-camera"></i>
                  </div>
                </div>
              </el-form-item>
            </el-row>
          </el-card>
          <el-card>
            <div slot="header" class="clearfix">
              <span>展示平台</span>
            </div>

            <el-row>
              <el-col :span="4">
                <el-form-item label="微信小程序">
                  <div>
                    <el-switch v-model="form.show_wxapp">
                    </el-switch>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="抖音小程序">
                  <div>
                    <el-switch v-model="form.show_byte_mini">
                    </el-switch>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <el-card>
            <div slot="header" class="clearfix">
              <span>其他信息</span>
            </div>
            <el-row>
              <el-col :span="12">
                <el-form-item label="店铺资质" class='cus-font' />
                <el-form-item label="单位名称">
                  <el-input class="input-b" v-model="form.company_name" placeholder="请输入单位名称"></el-input>
                </el-form-item>
                <el-form-item label="许可证号">
                  <el-input class="input-b" v-model="form.license_key" placeholder="请输入许可证号"></el-input>
                </el-form-item>
                <el-form-item label="营业执照">
                  <div style="display: inline-block">
                    <div @click="handleImgPicker('business_license_img')" class="upload-box">
                      <img v-if="form.business_license_img" :src="form.business_license_img" class="avatar" />
                      <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                    </div>
                    <div class="frm-tips">只能上传jpg/png文件，且不超过2M </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="门店顾问企业微信" class='cus-font' />
                <el-form-item label="展示门店顾问">
                  <el-switch v-model="form.show_wechat_img" active-color="#13ce66" />
                  <!-- <div class="frm-tips">导购企业微信是否展示在店铺首页</div> -->
                </el-form-item>
                <el-form-item label="门店顾问企微码">
                  <div @click="handleImgPicker('wechat_img')" class="upload-box">
                    <img v-if="form.wechat_img" :src="form.wechat_img" class="avatar" />
                    <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
                  </div>
                  <div class="frm-tips">只能上传jpg/png文件，且不超过2M </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <div class="global_footer section-footer with-border content-center">
            <el-button size="large" @click="cancelSubmit" style="margin-right: 15px">取消</el-button>
            <el-button type="primary" @click="submitItemsActionConfirm" :loading="submitLoading">{{ submitLoading ? '提交中' :
              '保存' }}</el-button>
          </div>
          <div id="qqmap_infowin" v-show="qqmap_infowin_flag">
            <el-col>
              <el-button type="primary" @click="imp_poi(poi_info)">导入该位置信息</el-button>
            </el-col>
            <el-col>{{ poi_info.name }}</el-col>
            <div class="frm-tips">{{ poi_info.address }}</div>
          </div>
        </el-col>
        <el-col :span="5" class="right-content">
          <el-card v-if="!distributor_type && login_type !== 'admin'">
            <div slot="header" class="clearfix">
              <span>货品管理配置</span>
            </div>
            <el-form-item label="自行上架">
              <el-switch v-model="form.is_op_can_sale" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-card>

          <el-card>
            <div slot="header" class="clearfix">
              <span>展示平台</span>
            </div>
            <el-form-item label="微信小程序">
              <el-switch v-model="form.show_wxapp" active-color="#13ce66" />
            </el-form-item>
            <el-form-item label="抖音小程序">
              <el-switch v-model="form.show_byte_mini" active-color="#13ce66" />
            </el-form-item>
          </el-card>
          <el-card>
            <div slot="header" class="clearfix">
              <span>自动化配置</span>
            </div>
            <el-form-item label="自动接单">
              <el-switch v-model="form.auto_receiving" active-color="#13ce66"></el-switch>
            </el-form-item>
            <el-form-item label="自动呼叫快递">
              <el-switch v-model="form.auto_call_delivery" active-color="#13ce66"></el-switch>
            </el-form-item>
          </el-card>
          <el-card>
            <div slot="header" class="clearfix">
              <span>支付设置</span>
            </div>
            <el-form-item label="分期付款">
              <el-switch
              v-model="form.open_fq_pay"
              active-color="#13ce66"
              active-value="1"
              inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-card>
          <el-card>
            <div slot="header" class="clearfix">
              <span>售后维修配置</span>
            </div>
            <el-form-item label="维修支持">
              <el-switch v-model="form.support_repair" active-color="#13ce66" :active-value="1"
                :inactive-value="0"></el-switch>
              <!-- <el-switch v-else disabled v-model="form.is_ziti" active-color="#13ce66"></el-switch> -->
            </el-form-item>
            <el-form-item label="维修类型">
              <el-radio-group v-model="form.is_repair_asp">
                <el-radio-button :label="0">非ASP</el-radio-button>
                <el-radio-button :label="1">ASP</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <div class='flex-desc' style="border:none">
              <span>关联维修范围</span>
              <el-button type='text' @click='showRepairDialog("维修范围设置", "repairRange")'>配置</el-button>
            </div>
            <div class='flex-desc' style="border:none">
              <span>现场维修设置</span>
              <el-button type='text' @click='showRepairDialog("现场维修设置", "repairsScene")'>配置</el-button>
            </div>
            <div class='flex-desc' style="border:none">
              <span class="tips">设置门店可现场维修产品需先关联相关维修产品置</span>
            </div>
          </el-card>

          <el-card>
            <div slot="header" class="clearfix">
              <span>履约方式配置</span>
            </div>
            <el-form-item label="自提">
              <el-switch v-if="!form.lng && !form.lat" disabled v-model="form.is_ziti" active-color="#13ce66"></el-switch>
              <el-switch v-else disabled v-model="form.is_ziti" active-color="#13ce66"></el-switch>
            </el-form-item>
            <el-form-item label="快递" v-if="!distributor_type">
              <el-switch v-model="form.is_delivery" active-color="#13ce66" @change=closeDelivery></el-switch>
            </el-form-item>
            <div class='flex-desc' v-if="form.is_delivery">
              <span>开启快递方式需先配置顺丰快递相关信息，如未完成，请先去完成配置</span>
              <el-button type='text' @click='sf_dialog = true'>{{ sf_form.project_code ? '已配置' : '去配置' }}</el-button>
            </div>
            <el-form-item label="小时达" v-if="!distributor_type">
              <el-switch v-model="form.urgent_delivery" active-color="#13ce66"></el-switch>
            </el-form-item>
            <el-form-item label="小时达承运方">
              <el-radio-group v-model="form.urgent_delivery_type">
                <el-radio-button label="mtps">美团</el-radio-button>
                <el-radio-button label="sftc">顺丰</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="顺丰急送兜底" v-if="form.urgent_delivery_type === 'mtps'">
              <el-switch v-model="form.last_use_sf" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0">
              </el-switch>
            </el-form-item>
            <el-form-item label="配送服务代码">
              <span>{{form.delivery_service_code}}</span>
            </el-form-item>
            <el-form-item label="开启远单">
              <span v-if="form.delivery_service_code_ext == 100009">是</span>
              <span v-else>否</span>
            </el-form-item>
          </el-card>

          <el-card>
            <div slot="header" class="clearfix">
              <span>开票方式配置</span>
            </div>
            <!-- <el-form-item label="扫码开票">
              <el-switch v-model="form.support_scan_invoice" :active-value="1" :inactive-value="0" active-color="#13ce66">
              </el-switch>
            </el-form-item> -->
            <el-form-item label="电子发票">
              <el-switch v-model="form.support_electronic_invoice" :active-value="1" :inactive-value="0"
                active-color="#13ce66">
              </el-switch>
            </el-form-item>
            <!-- <el-form-item label="纸制发票">
              <div>
                <el-switch v-model="form.support_paper_invoice" :active-value="1" :inactive-value="0"
                  active-color="#13ce66">
                </el-switch>
              </div>
            </el-form-item> -->
          </el-card>

          <el-card>
            <div slot="header" class="clearfix">
              <span>活动支持配置</span>
            </div>
            <el-form-item label="国补">
              <el-select v-model="form.subsidy_source" placeholder="请选择">
                <el-option
                  v-for="item in subsidyOffline"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="以旧换新">
              <el-switch v-model="form.is_old" active-color="#13ce66" @change="onIsOldChange"></el-switch>
            </el-form-item>
            <el-form-item label="支持到店换新">
              <el-switch v-model="form.is_old_instore" :active-value="1" :inactive-value="0" active-color="#13ce66" @change="onIsOldInstoreChange"></el-switch>
            </el-form-item>
            <el-form-item label="教育优惠" v-if="$route.query.distributor_id">
              <div></div>
            </el-form-item>
            <div class='flex-desc' style="border:none" v-if="$route.query.distributor_id">
              <span>开启教育优惠需先配置授权信息，如未完成，请先去完成配置。</span>
              <el-button type='text' @click='edu_dialog = true' style="cursor: pointer;">{{ edu_form.appid ? '已配置' :
                '去配置' }}</el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </section>

    <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog">
    </imgPicker>
    <DealerSelect :store-visible="DealerVisible" :is-valid="false" :rel-data-ids="relDealers" :get-status="DealerStatus"
      @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction" :maxSelect="1"></DealerSelect>
    <el-dialog title="教育优惠授权配置" :visible.sync="edu_dialog" width="500px" :destroy-on-close="true">
      <div class="demo-drawer__content">
        <el-form ref="edu_form" :model="edu_form" label-width="110px" :rules="edu_rules">
          <el-form-item label="appid" prop='appid'>
            <el-input placeholder="请输入appid" v-model="edu_form.appid" style="width:300px" />
          </el-form-item>
          <el-form-item label="secret" prop="secret" style="margin:40px 0px;">
            <el-input placeholder="请输入secret" v-model="edu_form.secret" style="width:300px" />
            <br />
          </el-form-item>
          <div class="section-footer content-center">
            <el-button @click="eduCancelChange">取消</el-button>
            <el-button type="primary" v-loading="edu_loading" @click="saveEduSetting('edu_form')">保存</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="顺丰快递配置" :visible.sync="sf_dialog" width="500px" :destroy-on-close="true">
      <div class="demo-drawer__content">
        <el-form ref="sf_form" :model="sf_form" label-width="120px" :rules="sf_rules">
          <el-form-item label="项目编码" prop='project_code'>
            <el-input placeholder="请输入项目编码" v-model="sf_form.project_code" style="width:280px" />
          </el-form-item>
          <el-form-item label="客户编码" prop="customer_code">
            <el-input placeholder="请输入客户编码" v-model="sf_form.customer_code" style="width:280px" />
            <br />
          </el-form-item>
          <el-form-item label="站点编码" prop="start_station_code">
            <el-input placeholder="请输入站点编码" v-model="sf_form.start_station_code" style="width:280px" />
          </el-form-item>
          <el-form-item label="是否代发货点" prop="is_consignment">
            <el-switch v-model="sf_form.is_open_substitute_delivery" active-color="#13ce66" inactive-color="#ff4949"
              active-value="1" inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item label="开启呼叫转移" prop="sf_call_transfer">
            <el-switch v-model="sf_form.sf_call_transfer" active-color="#13ce66" inactive-color="#ff4949" active-value="1"
              inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item label="备用发货地" prop="">
            <el-button @click="selectDeliverAlternatePlace" type="primary" size="mini" style="margin-bottom: 10px">
              选择
            </el-button>
            <el-card style="margin-right: 20px; width: 280px"
              v-if="this.alternatePlaceInfo !== undefined && JSON.stringify(this.alternatePlaceInfo) !== '{}'">
              <div>
                编码：{{ this.alternatePlaceInfo.code }}
              </div>
              <div>
                名称：{{ this.alternatePlaceInfo.name }}
              </div>
              <div>
                所属经销商：{{ this.alternatePlaceInfo.dealer_name }}
              </div>
              <div>
                地址：{{ this.alternatePlaceInfo.address }}
              </div>
            </el-card>
          </el-form-item>
          <div class="section-footer content-center">
            <el-button @click="eduCancelChange" style="margin-right: 20px">取消</el-button>
            <el-button type="primary" v-loading="edu_loading" @click="saveSFSetting('sf_form')">保存</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog title="选择备用发货地" :visible.sync="alternatePlaceDialog" width="60%">
      <div>
        <el-input class="input-m" v-model="sf_alternate_params.name" clearable @change="getAlternatePlaceList"
          placeholder="请输入名称">
        </el-input>
        <el-input v-if="alternatePlaceType === 'distributor'" class="input-m" v-model="sf_alternate_params.shop_code"
          clearable @change="getAlternatePlaceList" placeholder="请输入店铺AppleID">
        </el-input>
        <el-input v-if="alternatePlaceType === 'temporaryDeliveryPlace'" clearable @change="getAlternatePlaceList"
          class="input-m" v-model="sf_alternate_params.code" placeholder="请输入发货点编码">
        </el-input>
      </div>
      <el-tabs v-model="alternatePlaceType" @tab-click="changeAlternatePlaceType">
        <el-tab-pane label="店铺" name="distributor"></el-tab-pane>
        <el-tab-pane label="临时发货点" name="temporaryDeliveryPlace"></el-tab-pane>
      </el-tabs>
      <el-table v-if="alternatePlaceType === 'distributor'" :data="alternatePlaceList"
        v-loading="alternatePlaceTableLoading" highlight-current-row @current-change="handleAlternatePlaceChange">
        <el-table-column label="ID" prop="distributor_id" width="70px"></el-table-column>
        <el-table-column label="店铺名称" prop="name"></el-table-column>
        <el-table-column label="AppleID" prop="shop_code"></el-table-column>
        <el-table-column label="地址" prop="name">
          <template slot-scope="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
      </el-table>

      <el-table v-if="alternatePlaceType === 'temporaryDeliveryPlace'" :data="alternatePlaceList"
        v-loading="alternatePlaceTableLoading" highlight-current-row @current-change="handleAlternatePlaceChange">
        <el-table-column label="ID" prop="id" width="70px"></el-table-column>
        <el-table-column label="发货点名称" prop="name"></el-table-column>
        <el-table-column label="发货点编号" prop="code"></el-table-column>
        <el-table-column label="地址" prop="name">
          <template slot-scope="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background layout="total, prev, pager, next, jumper" @current-change="getAlternatePlaceList"
        :current-page.sync="alternatePlacePageNation.page" :page-size="alternatePlacePageNation.page_size"
        :total="alternatePlacePageNation.total_count">
      </el-pagination>

      <span slot="footer" class="dialog-footer">
        <el-button @click="alternatePlaceDialog = false">取 消</el-button>
        <el-button type="primary" @click="selectAlternatePlace">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogrRepairVisible" width="600px">
      <el-form label-position="left" label-width="150px">
        <el-form-item label="维修范围" required v-if="repairtype === 'repairRange'">
          <el-card v-for="(item, index) in repairRangeList" :key="item.id" shadow="never" class="crad">
            <div class="card-header" @click="handleChangeShow(item)"> <i
                :class="item.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
              <span class="product_name">{{ item.product_name }}</span>
            </div>
            <el-collapse-transition>
              <div v-show="item.show" class="transition-box">
                <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                  @change="handleCheckAllChange($event, item)">全选</el-checkbox>
                <el-checkbox-group v-model="item.checkList" @change="handleCheckedChange($event, item, index)">
                  <el-checkbox v-for="ele in item.repair_contents" :key="ele.product_rel_content_id"
                    :label="ele.product_rel_content_id">{{
                      ele.content_name }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-collapse-transition>
          </el-card>
        </el-form-item>
        <el-form-item label="可现场维修内容" required v-if="repairtype === 'repairsScene'">
          <el-card v-for="(item,index) in repairSceneList" :key="item.id" shadow="never" class="crad">
            <div class="card-header" @click="handleChangeShow(item)"> <i
                :class="item.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
              <span class="product_name">{{ item.product_name }}</span>
            </div>
            <el-collapse-transition>
              <div v-show="item.show" class="transition-box">
                <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll"
                  @change="handleCheckSceneAllChange($event, item)">全选</el-checkbox>
                <el-checkbox-group v-model="item.checkList" @change="handleCheckedSceneChange($event, item)">
                  <el-checkbox v-for="item in item.contents" :key="item.product_rel_content_id"
                    :label="item.product_rel_content_id">{{
                      item.content_name }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-collapse-transition>
          </el-card>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogrRepairVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlesubmitRepair">确 定</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>
<script>
import imgPicker from '@/components/imageselect'
import district from '@/common/district.json'
import { saveDistributor, getDistributorInfo, getDealerList, saveEduConfig, saveSFConfig, getDistributorList, getTemporaryDeliveryPlaceList, getEduConfig } from '@/api/marketing'
import { saveDistributorProductContent, savefieldRepairable, getDistributorProductsContents,getProductcontent } from '@/api/workandrest'
import { getRegionauth } from '@/api/regionauth'
import { getSetting } from '@/api/fenzhang'
import DealerSelect from '@/components/function/dealerSelect'
import { dragIssuesOptions } from '@/consts'
import draggable from 'vuedraggable'

import { mapGetters } from 'vuex'

// 取选中地区的值
function getCascaderObj(val, opt) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm.value === value) {
        opt = itm.children
        return itm
      }
    }
    return null
  })
}

export default {
  components: {
    imgPicker,
    DealerSelect,
    draggable,
  },
  data() {
    return {
      dragIssuesOptions,
      imgDialog: false,
      isGetImage: false,
      DealerVisible: false,
      DealerStatus: false,
      relDealers: [],
      login_type: '',

      hours: [
        {
          startWeek: '周一',
          endWeek: '周日',
          startTime: '08:00',
          endTime: '21:00'
        }
      ],
      startWeek: '',
      endWeek: '',
      startTime: '08:00',
      endTime: '21:00',
      submitLoading: false,
      pickerImgType: 'logo',
      form: {
        examine_status: 0,
        byte_mini_shop_id: '',
        login_type: '',
        shop_type: 'monopolized',
        is_valid: 'true',
        address: '',
        is_ziti: true,
        is_old: false,
        is_old_instore: false,
        subsidy_source: '',
        is_delivery: true,
        urgent_delivery: true,
        is_op_can_sale: true,
        // is_op_distribution: false,
        support_repair: 0, //0不支持，1支持
        is_repair_asp: 0, //0 非ASP 1 ASP
        shop_code: '',
        alias_name: '',
        shop_enjoy1: '取货尊享',
        hour: [],
        // rate: '0.00',
        // is_open: false,
        dealer_id: 0,
        lat: 39.916527,
        lng: 116.397128,
        shop_pics: [],
        distributor_lv: '',
        city_lv: '',
        market_name: '',
        support_paper_invoice: true,
        support_electronic_invoice: true,
        support_scan_invoice: false,
        wechat_img: '',
        show_wechat_img: false,
        company_name: '',
        license_key: '',
        business_license_img: '',
        contract_phone: '',
        show_wxapp: 1,
        auto_receiving: 1,
        auto_call_delivery: 1,
        show_byte_mini: 1,
        urgent_delivery_type: 'mtps',
        last_use_sf: 0,
        open_fq_pay: '0'
      },
      distributor_id: 0,
      distributor_type: '',
      searchRegion: '',
      qqmap_infowin_flag: false,
      regions: district,
      searchService: [],
      markers: [],
      poi_info: [],
      rules: {
        shop_code: [{ pattern: /^[A-Za-z0-9_]+$/, message: '请输入正确格式的店铺号' }],
      },
      regionauth_list: [],
      checkRepairList: [],//选择维修
      repairRangeList: [],//关联维修范围
      repairSceneList: [],//现场维修设置
      title: '维修范围设置',
      dialogrRepairVisible: false,
      repairtype: 'RepairRange',

      isOpen: false,
      options: [{
        value: '周一',
        label: '周一'
      }, {
        value: '周二',
        label: '周二'
      }, {
        value: '周三',
        label: '周三'
      }, {
        value: '周四',
        label: '周四'
      }, {
        value: '周五',
        label: '周五'
      }, {
        value: '周六',
        label: '周六'
      }, {
        value: '周日',
        label: '周日'
      }],
      edu_dialog: false,
      edu_form: {
        appid: '',
        secret: ''
      },
      edu_rules: {
        appid: [
          { required: true, message: '请输入appid', trigger: 'blur' }
        ],
        secret: [
          { required: true, message: '请输入secret', trigger: 'blur' }
        ]
      },
      edu_loading: false,
      sf_dialog: false,
      sf_form: {
        distributor_id: '',
        project_code: '',
        customer_code: '',
        start_station_code: '',
        is_open_substitute_delivery: '',
        sf_call_transfer: '',
        alternate_place_type: '',
        alternate_place_id: 0,
      },
      sf_formRes: {},
      sf_rules: {
        distributor_id: [
          { required: true, message: '请检查店铺ID', trigger: 'blur' }
        ],
        project_code: [
          { required: true, message: '请输入 项目编码', trigger: 'blur' }
        ],
        customer_code: [
          { required: true, message: '请输入 客户编码', trigger: 'blur' }
        ],
        start_station_code: [
          { required: true, message: '请输入 站点编码', trigger: 'blur' }
        ],
      },
      sf_alternate_params: {
        name: '',
        shop_code: '',
        code: '',
        is_valid: 'true',
        is_open_substitute_delivery: 1
      },
      alternatePlaceDialog: false,
      alternatePlaceType: 'distributor',
      alternatePlaceTableLoading: false,
      selectAlternatePlaceRow: {},
      alternatePlaceList: [],
      alternatePlaceInfo: {},
      alternatePlacePageNation: {
        total_count: 0,
        page: 1,
        page_size: 20,
      },
      subsidyOffline: [
        {
          label: '无',
          value: ''
        },
        {
          label: '线下',
          value: 'offline'
        },
        {
          label: '线上',
          value: 'online'
        },
        {
          label: '线上加线下',
          value: 'all'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    pickImg(data) {
      if (this.pickerImgType === 'logo') {
        this.form.logo = data.url !== undefined ? data.url : ''
      } else if (this.pickerImgType === 'wechat_img') {
        this.form.wechat_img = data.url !== undefined ? data.url : ''
      } else if (this.pickerImgType === 'business_license_img') {
        this.form.business_license_img = data.url !== undefined ? data.url : ''
      } else {
        this.form.shop_pics.push(data.url)
      }
      this.imgDialog = false
    },
    handleImgPicker(pickerImgType) {
      this.pickerImgType = pickerImgType
      this.imgDialog = true
      this.isGetImage = true
    },
    closeImgDialog() {
      this.imgDialog = false
      this.isGetImage = false
    },
    cancelSubmit() {
      this.$router.go(-1)
    },
    removePicsImg(index) {
      this.form.shop_pics.splice(index, 1)
    },
    handleCheckAllChange(e, item) {
      item.isIndeterminate = false;
      item.checkList = e ? item.repair_contents.map(ele => ele.product_rel_content_id) : []
    },
    handleCheckSceneAllChange(e, item) {
      item.isIndeterminate = false;
      item.checkList = e ? item.contents.map(ele => ele.product_rel_content_id) : []
    },
    handleCheckedChange(value, item, index) {
      let checkedCount = value.length;
      item.checkAll = item.repair_contents.length === checkedCount
      item.isIndeterminate = checkedCount > 0 && checkedCount < item.repair_contents.length;
    },
    handleCheckedSceneChange(value, item, index) {
      let checkedCount = value.length;
      item.checkAll = item.contents.length === checkedCount
      item.isIndeterminate = checkedCount > 0 && checkedCount < item.contents.length;
    },
    submitItemsActionConfirm() {
      this.submitLoading = true
      if (this.form.shop_code) {
        this.form.shop_code = this.form.shop_code
      }

      //
      console.log("debug---this.form.is_delivery:", this.form.is_delivery)
      console.log("debug---this.form.is_old:", this.form.is_old)
      // if (this.form.is_old && !this.form.is_delivery) {
      //   this.$message({
      //     message: '开启以旧换新，需开启快递！',
      //     type: 'warning',
      //   })
      //   this.submitLoading = false

      //   return ''
      // }
      if (this.form.urgent_delivery && !this.form.is_delivery) {
        this.$message({
          message: '开启小时达，需开启快递！',
          type: 'warning',
        })
        this.submitLoading = false

        return ''
      }
      // this.submitLoading = false
      // return false;

      this.form.dealer_id = this.relDealers[0] != undefined ? this.relDealers[0].dealer_id : 0
      let newHours = []
      this.hours.map(item => {
        newHours.push([item.startWeek, item.endWeek, item.startTime, item.endTime])
      })
      this.form.hour = JSON.stringify(newHours)
      if (this.form.shop_enjoy) {
        this.form.shop_enjoy = this.form.shop_enjoy
        this.form.shop_enjoy1 = "取货尊享"
      }
      // if (this.form.is_op_distribution) {
      delete this.form.is_op_distribution;
      // }

      if (!this.form.shop_type) {
        this.$message({
          message: '请选择店铺类型！',
          type: 'warning'
        })
        this.submitLoading = false

        return ''
      }
      console.log(this.form);

      saveDistributor(this.form)
        .then((res) => {
          this.submitLoading = false
          if (res.data.data.message) {
            this.$message({ type: 'warning', message: res.data.data.message })
          } else {
            this.$message({ type: 'success', message: '保存店铺成功' })
            if (this.$store.getters.login_type != 'distributor') {
              this.$router.go(-1)
            }
          }
          // console.log(res)
          // console.log(res.data.data.code)
          // if(res.data.data.code == "0000"){
          // }else{
          //   this.$message({ type: 'warning', message: "保存失败：" + res.data.data.code + res.data.data.message  })
          //   this.submitLoading = false
          // }
        })
        .catch((error) => {
          this.$message({ type: 'warning', message: "保存失败" })
          this.submitLoading = false
        })
    },
    handleRegionChange: function (value) {
      var vals = getCascaderObj(value, this.regions)
      this.form.regions_id = []
      this.form.regions = []
      for (var i = 0; i < vals.length; i++) {
        this.form.regions_id[i] = vals[i].value
        this.form.regions[i] = vals[i].label
      }

      if (this.form.regions[0] == '北京市' || this.form.regions[0] == '天津市' || this.form.regions[0] == '上海市' || this.form.regions[0] == '重庆市') {
        this.searchRegion = this.form.regions[0] + this.form.regions[2]
      } else {
        if (this.form.regions[1] === this.form.regions[2]) {
          this.searchRegion = this.form.regions[0] + this.form.regions[1]
        } else {
          this.searchRegion = this.form.regions[0] + this.form.regions[1] + this.form.regions[2]
        }
      }
    },
    initMap(lat, lng) { // 初始化地图
      const center = new qq.maps.LatLng(lat, lng)
      this.map = new qq.maps.Map(document.getElementById('qqmap_container'), {
        center: center,
        zoom: 13,
      })

      this.initSearchService()
      this.setMarker(center)
    },
    setMapCenter() {
      this.clearOverlays(this.markers)
      const center = new qq.maps.LatLng(this.form.lat, this.form.lng)
      this.map.setCenter(center)
      this.setMarker(center)
    },
    setMarker(center) {
      var marker = new qq.maps.Marker({
        position: center,
        map: this.map,
      })
      this.markers.push(marker)
    },
    initSearchService() {
      var that = this
      //设置Poi检索服务，用于本地检索、周边检索
      this.searchService = new qq.maps.SearchService({
        panel: document.getElementById('qqmap_container'),
        //检索成功的回调函数
        complete: function (results) {
          //设置回调函数参数
          var pois = results.detail.pois
          var infoWin = new qq.maps.InfoWindow({
            map: that.map,
          })
          var latlngBounds = new qq.maps.LatLngBounds()
          for (var i = 0, l = pois.length; i < l; i++) {
            var poi = pois[i]
            //扩展边界范围，用来包含搜索到的Poi点
            latlngBounds.extend(poi.latLng)
              ; (function (n) {
                var marker = new qq.maps.Marker({
                  map: that.map,
                })
                marker.setPosition(pois[n].latLng)

                marker.setTitle(i + 1)
                that.markers.push(marker)
                qq.maps.event.addListener(marker, 'click', function () {
                  infoWin.open()
                  that.qqmap_infowin_flag = true
                  that.poi_info = pois[n] //将选点位置信息存入poi_info
                  infoWin.setContent(document.getElementById('qqmap_infowin'))
                  infoWin.setPosition(pois[n].latLng)
                })
              })(i)
          }
          that.map.fitBounds(latlngBounds)
        },
        //若服务请求失败，则运行以下函数
        error: function (e) {
          this.$message.error('未查询到数据')
        },
      })

      console.log(this.searchService, 485)
    },
    clearOverlays: function (overlays) {
      //清除地图上的marker
      var overlay
      while ((overlay = overlays.pop())) {
        overlay.setMap(null)
      }
    },
    searchKeyword() {
      //设置搜索的范围和关键字等属性
      if (!this.searchRegion) {
        this.$message({
          message: '请选择地区',
          type: 'warning',
        })
        return ''
      }
      if (!this.form.address) {
        this.$message({
          message: '请输入具体位置',
          type: 'warning',
        })
        return ''
      }
      var region = this.searchRegion
      var keyword = this.form.address
      var pageIndex = 0
      var pageCapacity = 5
      this.clearOverlays(this.markers)
      //根据输入的城市设置搜索范围
      this.searchService.setLocation(region)
      //设置搜索页码
      this.searchService.setPageIndex(pageIndex)
      //设置每页的结果数
      this.searchService.setPageCapacity(pageCapacity)
      //根据输入的关键字在搜索范围内检索
      this.searchService.search(keyword)
    },
    imp_poi: function (poi_info) {
      this.form.lng = poi_info.latLng.lng
      this.form.lat = poi_info.latLng.lat
      this.form.address = poi_info.name
    },
    removeByValue: function (arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == val) {
          arr.splice(i, 1)
          break
        }
      }
      return arr
    },
    getRegionauthList() {
      this.loading = true
      getRegionauth().then((res) => {
        this.regionauth_list = res.data.data.list
        this.regionauth_total = res.data.data.total_count
        this.loading = false
      })
    },
    addItem() {
      if (!this.hours) {
        this.hours = []
      }
      let item = {
        startWeek: '',
        endWeek: '',
        startTime: '',
        endTime: ''
      }
      if (this.hours.length > 3) {
        this.$message({
          message: '最多添加3个时间段',
          type: 'error',
        })
      } else {
        this.hours.push(item)
      }
    },
    removeItem(index) {
      this.hours.splice(index, 1)
    },
    openDialog() {
      this.DealerVisible = true;
    },
    DealerChooseAction(data) {
      console.log(541, data)
      this.DealerVisible = false
      this.DealerStatus = false
      // if (data === null || data.length <= 0) return
      this.relDealers = data
    },
    closeDialogAction() {
      this.DealerStatus = false
      this.DealerVisible = false
    },
    getDealer(ids) {
      if (ids > 0) {
        let param = { dealer_id: ids }
        getDealerList(param).then(res => {
          this.relDealers = res.data.data.list
        })
      }
    },
    isDeliverySetting() {
      console.log('打开弹框')
    },
    eduCancelChange() {
      this.edu_dialog = false
      // this.edu_form = {}
      this.sf_dialog = false
      // this.sf_form = {}
      this.sf_formRes = {}
      this.sf_alternate_params = {}
      this.alternatePlaceDialog = false
      this.alternatePlacePageNation = {}
      this.edu_loading = false
    },
    saveEduSetting(edu_form) {
      this.$refs[edu_form].validate(valid => {
        if (valid) {
          this.edu_loading = true
          saveEduConfig(this.edu_form).then(response => {
            let res = response.data, type = 'error', msg = '操作失败';
            console.log(res, '========');
            if (res) {
              type = 'success';
              msg = res.message ? res.message : '操作成功';
            }
            this.$message({
              type: type,
              message: msg,
              duration: 3 * 1000
            });
            this.eduCancelChange()
          }).catch(error => {
            // this.$message.error('操作失败，请检查AdaPay支付配置或联系管理员');
          })
        }
        this.edu_loading = false
      })
    },
    saveSFSetting(sf_form) {
      this.$refs[sf_form].validate(valid => {
        if (valid) {
          // 备用发货地校验
          if (this.sf_form.sf_call_transfer == '1' && !this.sf_form.alternate_place_id) {
            this.$message.error('请选择备用发货地')
            return false
          }
          this.edu_loading = true
          saveSFConfig(this.sf_form).then(response => {
            let res = response.data, type = 'error', msg = '操作失败';
            console.log(res, '========');
            if (res) {
              type = 'success';
              msg = res.message ? res.message : '操作成功';
              this.form = { ...this.form, ...this.sf_form }
            }
            this.$message({
              type: type,
              message: msg,
              duration: 3 * 1000
            });
            this.eduCancelChange()
          }).catch(error => {
            // this.$message.error('操作失败，请检查AdaPay支付配置或联系管理员');
          })
        }
        this.edu_loading = false
      })
    },
    selectDeliverAlternatePlace() {
      this.alternatePlaceDialog = true
      this.getAlternatePlaceList()
    },
    changeAlternatePlaceType() {
      this.getAlternatePlaceList()
    },
    getAlternatePlaceList() {
      this.alternatePlaceTableLoading = true
      if (this.alternatePlaceType === 'distributor') {
        this.getAlternateDistributors()
      } else if (this.alternatePlaceType === 'temporaryDeliveryPlace') {
        this.getTemporaryDeliveryPlace()
      } else {
        this.alternatePlaceList = []
      }
    },
    getAlternateDistributors() {
      let params = JSON.parse(JSON.stringify(this.sf_alternate_params))
      params.page = this.alternatePlacePageNation.page
      params.pageSize = this.alternatePlacePageNation.page_size
      params.dealer_id = this.SFSettingRow.dealer_id
      params.province = this.SFSettingRow.province
      params.city = this.SFSettingRow.city
      getDistributorList(params).then((response) => {
        if (response.data.data.list) {
          this.alternatePlaceList = response.data.data.list
          this.alternatePlacePageNation.total_count = response.data.data.total_count
        }
        this.alternatePlaceTableLoading = false
      })
    },
    getTemporaryDeliveryPlace() {
      let params = JSON.parse(JSON.stringify(this.sf_alternate_params))
      params.page = this.alternatePlacePageNation.page
      params.pageSize = this.alternatePlacePageNation.page_size
      params.dealer_id = this.SFSettingRow.dealer_id
      params.province = this.SFSettingRow.province
      params.city = this.SFSettingRow.city
      getTemporaryDeliveryPlaceList(params).then((response) => {
        if (response.data.data.list) {
          this.alternatePlaceList = response.data.data.list
          this.alternatePlacePageNation.total_count = response.data.data.total_count
        }
        this.alternatePlaceTableLoading = false
      })
    },
    selectAlternatePlace() {
      if (JSON.stringify(this.selectAlternatePlaceRow || {}) === '{}') { // 未选择
        this.alternatePlaceInfo = {}
        this.sf_form.alternate_place_type = ''
        this.sf_form.alternate_place_id = 0
        this.alternatePlaceDialog = false
        return true;
      }

      this.sf_form.alternate_place_type = this.alternatePlaceType
      if (this.alternatePlaceType === 'distributor') {
        this.sf_form.alternate_place_id = this.selectAlternatePlaceRow.distributor_id
        this.alternatePlaceInfo = {
          name: this.selectAlternatePlaceRow.name,
          code: this.selectAlternatePlaceRow.shop_code,
          dealer_name: this.selectAlternatePlaceRow.dealer_name,
          address: this.selectAlternatePlaceRow.address,
        }
      } else {
        this.sf_form.alternate_place_id = this.selectAlternatePlaceRow.id
        this.alternatePlaceInfo = {
          name: this.selectAlternatePlaceRow.name,
          code: this.selectAlternatePlaceRow.code,
          dealer_name: this.selectAlternatePlaceRow.dealer_name,
          address: this.selectAlternatePlaceRow.address,
        }
      }
      this.selectAlternatePlaceRow = {}
      this.alternatePlaceDialog = false
    },
    handleAlternatePlaceChange(row) {
      this.selectAlternatePlaceRow = row
    },
    getSfOrEduSetting() {
      const row = this.form
      this.SFSettingRow = JSON.parse(JSON.stringify(row))
      this.sf_form.distributor_id = row.distributor_id;
      this.sf_form.project_code = row.project_code;
      this.sf_form.customer_code = row.customer_code;
      this.sf_form.start_station_code = row.start_station_code;
      this.sf_form.is_open_substitute_delivery = row.is_open_substitute_delivery
      this.sf_form.sf_call_transfer = row.sf_call_transfer
      this.sf_form.alternate_place_id = row.alternate_place_id
      this.sf_form.alternate_place_type = row.alternate_place_type
      this.alternatePlaceInfo = row.alternatePlaceInfo || {}
      getEduConfig({ distributor_id: row.distributor_id }).then(res => {
        let result = res.data.data;
        this.edu_form.appid = result.appid;
        this.edu_form.secret = result.secret;
        this.edu_form.distributor_id = row.distributor_id
      }).catch(error => {
        console.log(error);
      });
    },
    handleChangeShow(item) {
      item.show = !item.show
    },


    handlesubmitRepair() {
      if (this.repairtype === 'repairRange') {
        let checkRepairList = []
        const content = this.repairRangeList.map(item => item.repair_contents).flat(Infinity)
        this.repairRangeList.forEach(item => { checkRepairList.push(...item.checkList) })
        const data = content.map(item => {
          if (checkRepairList.indexOf(item.product_rel_content_id) !== -1) {
            const { repair_product_id, content_id, product_rel_content_id } = item;
            return { "product_id": repair_product_id, "id": content_id, "product_rel_content_id": product_rel_content_id }
          }
        })
        saveDistributorProductContent({
          distributor_id: this.distributor_id,
          products_contents: data
        }).then(() => {
          this.$message.success('保存成功')
          this.dialogrRepairVisible = false
        })
      }
      if (this.repairtype === 'repairsScene') {
        const checkRepairList = []
        this.repairSceneList.forEach(item => { checkRepairList.push(...item.checkList) })
        savefieldRepairable({
          distributor_id: this.distributor_id,
          product_rel_content_id: checkRepairList.join(',')
        }).then(() => {
          this.$message.success('保存成功')
          this.dialogrRepairVisible = false
        })
      }
    },


    showRepairDialog(title, type) {
      this.title = title
      this.dialogrRepairVisible = true
      this.repairtype = type
      this.checkRepairList = []
      //现场维修设置
      getDistributorProductsContents(this.distributor_id).then(res => {
        const data = res.data.data
        if (type === 'repairsScene') {
          console.log(data);
          this.repairSceneList = data.map(item => {
            item.checkList=[]
            item.show = false;
            const { contents } = item;
            console.log(contents);
            contents.forEach(ele => {
              if (ele.field_repairable == 1) {
                item.checkList.push(ele.product_rel_content_id);
                item.show = true;
              }
            })
            item.checkAll = item.checkList.length === contents.length
            item.isIndeterminate = item.checkList.length !== contents.length
            return item
          })
        }
        if (type === 'repairRange') {
          this.repairRangeList.map(item => {
            data.forEach(k => {
              if (item.id === k.product_id) {
                item.checkList = k.contents.map(ele => ele.product_rel_content_id)
              }
            })
            item.checkAll = item.checkList.length === item.repair_contents.length
            item.isIndeterminate = item.checkList.length !== item.repair_contents.length
            return item
          })
        }
      })
    },
    closeDelivery(){
      if(!this.form.is_delivery){
        this.form.urgent_delivery = false
        this.$message.error('已自动关闭小时达')
      }
    },
    onIsOldChange (value) {
      if (!value) {
        this.form.is_old_instore = false
      }
    },
    onIsOldInstoreChange (value) {
      if (value) {
        this.form.is_old = true
      }
    }
  },
  mounted() {
    this.getRegionauthList()

    this.distributor_id = this.$route.query.distributor_id
    this.login_type = this.$store.getters.login_type

    console.log("::::login_type::", this.login_type)

    if (this.distributor_id || this.$store.getters.login_type == 'distributor') {
      getDistributorInfo({ distributor_id: this.distributor_id }).then((res) => {
        this.form = {
          ...res.data.data,
          shop_pics: res.data.data.shop_pics || [],
          // support_paper_invoice: res.data.data.support_paper_invoice == 1 ? true : false,
          // support_electronic_invoice: res.data.data.support_electronic_invoice == 1 ? true : false,
          // support_scan_invoice: res.data.data.support_scan_invoice == 1 ? true : false,
          show_wechat_img: res.data.data.show_wechat_img == 1 ? true : false,
        }
        if (res.data.data.hour) {
          // 处理营业时间的格式
          res.data.data.hour = JSON.parse(res.data.data.hour)
          this.hours = []
          // 处理营业时间的格式
          res.data.data.hour.map(item => {
            this.hours.push({
              startWeek: item[0],
              endWeek: item[1],
              startTime: item[2],
              endTime: item[3]
            })
          })
        }

        this.searchRegion = this.form.area
        if (!this.form.lat) {
          this.initMap()
        } else {
          this.initMap(this.form.lat, this.form.lng)
        }
        this.getDealer(res.data.data.dealer_id)
        this.getSfOrEduSetting()
      })
    } else {
      // 添加门店时初始化地图
      this.initMap(this.form.lat, this.form.lng)
    }
    this.distributor_type = this.$route.query.distributor_type
    if (this.distributor_type) {
      this.form.distributor_self = 1
    }
    getSetting().then(res => {
      let data = res.data.data
      this.isOpen = data.is_open == 'true'
    })

    getProductcontent().then(res => {
      this.repairRangeList = res.data.data.map(item => {
        item.show = false
        item.isIndeterminate = true
        item.checkAll = false
        item.checkList = []
        return item
      })
    })

  },
  destroyed() {
    this.map = null
  }
}
</script>

<style lang="scss">
.distributor_editor {
  .pics-box {
    overflow: hidden;

    .goodspic-wrap {
      float: left;
      margin-right: 5px;
      overflow: hidden;

      .goodspic {
        position: relative;
        float: left;
        width: 120px;
        height: 120px;
        margin: 0 5px 10px;
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .goodspic-mask {
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .2);
          cursor: pointer;

          &.on {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .iconfont {
            margin: 0 8px;
            font-size: 20px;
            color: #fff;
          }
        }

        .goodspic-mask:hover {
          display: block;
        }
      }

      .goodspic:hover {
        .goodspic-mask {
          display: block;
        }
      }
    }

    .upload-box {
      display: inline-block;
      width: 120px;
      height: 120px;
      line-height: 120px;
      text-align: center;

      .iconfont {
        font-size: 30px;
        color: #ccc;
      }
    }
  }

  .bm-view {
    width: 100%;
    height: 300px;
  }

  #qqmap_rslist {
    border-right: 1px solid #e7e7eb;
  }

  #qqmap_container {
    float: left;
    width: 100%;
    height: 400px;
  }

  .time-hours {
    margin-bottom: 20px;
  }

  .setting-remove {
    display: inline-block;
  }

  .box-card {
    width: 480px;
  }

  .global_footer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: calc(100% - 250px);
    background: #fff;
    padding: 12px 24px;
    line-height: 44px;
    z-index: 999;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
      0 -9px 28px 0 rgba(0, 0, 0, 0.05),
      0 -12px 48px 16px rgba(0, 0, 0, 0.03);
    transition: width .3s cubic-bezier(.645, .045, .355, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-content {
    .el-form-item {
      margin-top: 7px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .el-form-item__label {
      text-align: left;
      flex: 1 // width: 140px !important;
    }

    .el-form-item__content {
      margin-left: 0px !important;
    }

    .el-card__body {
      padding: 0px 20px;
      padding-bottom: 7px;
    }

    .flex-desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px dashed #ccc;

      span {
        width: 70%;
      }
    }
  }

  .cus-font {
    .el-form-item__label {
      width: 160px !important;
      font-size: 16px !important;
      text-align: center;
    }

    .el-form-item__content {
      margin-left: 0px !important;
    }
  }
}
</style>
